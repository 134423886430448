import queryString from "query-string";

import { SUNDRY_TEMPLATE } from "constants/actionTypes";
import { DeploymentPermissions } from "constants/permissions";

import { hasPermission } from "lib/permissions";
import { serializeSundryTemplate } from "lib/serializers/sundryTemplates";

import {
  getActiveLivestockAgentDeployment,
  getIsFetchingSundryTemplates,
} from "selectors";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "./lib";

const urlCreator = ({ changesSince, id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/sundry-templates/", id, action),
    query: {
      changesSince,
    },
  });

export const SundryTemplateAction = offlineActionCreator(
  SUNDRY_TEMPLATE,
  serializeSundryTemplate,
  urlCreator,
  false,
);

SundryTemplateAction.actionFilter = (state, changes = false) => {
  const enabled = hasPermission(
    getActiveLivestockAgentDeployment(state),
    DeploymentPermissions.featureBilling,
  );

  if (!enabled) {
    return null;
  } else if (!changes) {
    return SundryTemplateAction.request();
  } else if (changes && !getIsFetchingSundryTemplates(state)) {
    return SundryTemplateAction.requestChanges({
      changesSince: state.sundryTemplates.lastModifiedTimestamp,
    });
  } else {
    // we requested changes but are fetching already.
    return null;
  }
};
