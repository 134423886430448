import { all, debounce, put, select } from "redux-saga/effects";

import {
  AuctionPenAction,
  BillingDocumentAction,
  BillingRunAction,
  BusinessAction,
  CheckpointAction,
  CommentAction,
  fetchGeoData,
  LedgerEntryAction,
  ManualAdjustmentAction,
  MasterRuleAction,
  MasterRuleBookAction,
  NominationAction,
  PaymentAction,
  requestAttachmentsChanges,
  requestConsignmentsChanges,
  requestPropertiesChanges,
  requestScansChanges,
  RuleAction,
  RuleBookAction,
  SaleAction,
  SaleLotAction,
  SavedViewAction,
  ServerHeartbeatAction,
  WeighLotAction,
  WeighLotScanAction,
} from "actions";

import { IntegrationCredentialAction } from "actions/integrationCredentials";
import { InterestSettingsAction } from "actions/interest";
import { LedgerAccountAction } from "actions/ledgerAccounts";
import { MasterLedgerAccountAction } from "actions/masterLedgerAccounts";
import { PenScanLotAction } from "actions/penScanLots";
import { ReceivalLotAction } from "actions/receivalLots";
import { SundryTemplateAction } from "actions/sundryTemplates";
import { TradingTermAction } from "actions/tradingTerms";
import { VendorCommissionBandAction } from "actions/vendorCommissionBands";

import { GET_LIVESTOCK_SALE_DATA_CHANGES } from "constants/actionTypes";
import {
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";

import { hasPermission } from "lib/permissions";

import {
  getActiveBillingRunId,
  getDeploymentById,
  getEffectiveDeploymentId,
  getIsFetchingAuctionPens,
  getIsFetchingBillingDocuments,
  getIsFetchingBillingRuns,
  getIsFetchingBusinesses,
  getIsFetchingCheckpoints,
  getIsFetchingConsignments,
  getIsFetchingInterestSettings,
  getIsFetchingLedgerAccounts,
  getIsFetchingLedgerEntries,
  getIsFetchingManualAdjustments,
  getIsFetchingMasterLedgerAccounts,
  getIsFetchingMasterRuleBooks,
  getIsFetchingMasterRules,
  getIsFetchingNominations,
  getIsFetchingPayments,
  getIsFetchingPenScanLots,
  getIsFetchingProperties,
  getIsFetchingReceivalLots,
  getIsFetchingRuleBooks,
  getIsFetchingRules,
  getIsFetchingSaleLots,
  getIsFetchingSales,
  getIsFetchingSavedViews,
  getIsFetchingVendorCommissionBands,
  getIsFetchingWeighLots,
  getIsFetchingWeighLotScans,
  getIsLivestockAgent,
  getIsReadOnlyByBillingRunId,
  getIsSaleyardAdmin,
  selectCurrentSaleyard,
} from "selectors";

function* fetchLivestockSaleData(action) {
  const state = yield select();

  yield put(fetchGeoData());
  yield put(ServerHeartbeatAction.request());

  const permissionCheckActionTypes = [
    CommentAction,
    TradingTermAction,
    SundryTemplateAction,
  ];

  yield all(
    permissionCheckActionTypes
      .map(action => action.actionFilter(state, true))
      .filter(Boolean)
      .map(action => put(action)),
  );

  if (!getIsFetchingConsignments(state)) {
    yield put(requestConsignmentsChanges(action.sale));
  }

  if (!getIsFetchingSales(state)) {
    yield put(
      SaleAction.requestChanges({
        changesSince: state.sales.lastModifiedTimestamp,
        dontChangeSale: true,
      }),
    );
  }

  if (!getIsFetchingSaleLots(state)) {
    yield put(
      SaleLotAction.requestChanges({
        changesSince: state.saleLots.lastModifiedTimestamp,
      }),
    );
  }
  if (!state.scanners.isFetching) {
    yield put(requestScansChanges(action.sale));
  }
  if (!getIsFetchingBusinesses(state)) {
    yield put(
      BusinessAction.requestChanges({
        changesSince: state.businessesV2.lastModifiedTimestamp,
      }),
    );
  }
  if (!getIsFetchingProperties(state)) {
    yield put(requestPropertiesChanges());
  }

  if (!getIsFetchingAuctionPens(state)) {
    yield put(
      AuctionPenAction.requestChanges({
        changesSince: state.auctionPens.lastModifiedTimestamp,
      }),
    );
  }

  if (!getIsFetchingManualAdjustments(state)) {
    yield put(
      ManualAdjustmentAction.requestChanges({
        changesSince: state.manualAdjustments.lastModifiedTimestamp,
      }),
    );
  }

  if (!getIsFetchingPayments(state)) {
    yield put(
      PaymentAction.requestChanges({
        changesSince: state.payments.lastModifiedTimestamp,
        livestockSalesIdIn: [action.sale.livestocksale_id],
      }),
    );
  }

  if (!getIsFetchingNominations(state)) {
    yield put(
      NominationAction.requestChanges({
        changesSince: state.nominations.lastModifiedTimestamp,
      }),
    );
  }

  if (!getIsFetchingCheckpoints(state)) {
    yield put(
      CheckpointAction.requestChanges({
        changesSince: state.checkpoints.lastModifiedTimestamp,
      }),
    );
  }

  if (!getIsFetchingSavedViews(state)) {
    yield put(
      SavedViewAction.requestChanges({
        changesSince: state.savedViews.lastModifiedTimestamp,
      }),
    );
  }

  if (!getIsFetchingVendorCommissionBands(state)) {
    yield put(
      VendorCommissionBandAction.requestChanges({
        changesSince: state.vendorCommissionBands.lastModifiedTimestamp,
      }),
    );
  }

  if (!getIsFetchingInterestSettings(state)) {
    yield put(
      InterestSettingsAction.requestChanges({
        changesSince: state.interestSettings.lastModifiedTimestamp,
      }),
    );
  }

  if (!getIsFetchingReceivalLots(state)) {
    yield put(
      ReceivalLotAction.requestChanges({
        changesSince: state.receivalLots.lastModifiedTimestamp,
      }),
    );
  }

  if (!getIsFetchingPenScanLots(state)) {
    yield put(
      PenScanLotAction.requestChanges({
        changesSince: state.penScanLots.lastModifiedTimestamp,
      }),
    );
  }

  if (!getIsFetchingWeighLots(state)) {
    yield put(
      WeighLotAction.requestChanges({
        changesSince: state.weighLots.lastModifiedTimestamp,
      }),
    );
  }

  if (!getIsFetchingWeighLotScans(state)) {
    yield put(
      WeighLotScanAction.requestChanges({
        changesSince: state.weighLotScans.lastModifiedTimestamp,
      }),
    );
  }

  // No isFetching, no changes since.
  yield put(requestAttachmentsChanges(action.sale));

  // Billing
  const activeBillingRunId = getActiveBillingRunId(state);
  if (activeBillingRunId) {
    // If we have a writeable billing run active, check it for changes.

    const readOnly = getIsReadOnlyByBillingRunId(activeBillingRunId)(state);
    if (readOnly === false) {
      yield put(BillingRunAction.checkForChanges(activeBillingRunId));
    }

    // Standard get changes since.
    if (!getIsFetchingBillingRuns(state)) {
      yield put(
        BillingRunAction.requestChanges({
          changesSince: state.billingRuns.lastModifiedTimestamp,
          livestockSalesIdIn: action.sale.livestocksale_id,
        }),
      );
    }
    if (!getIsFetchingBillingDocuments(state)) {
      yield put(
        BillingDocumentAction.requestChanges({
          changesSince: state.billingDocuments.lastModifiedTimestamp,
          billingRunId: activeBillingRunId,
        }),
      );
    }
    if (!getIsFetchingLedgerEntries(state)) {
      yield put(
        LedgerEntryAction.requestChanges({
          changesSince: state.ledgerEntries.lastModifiedTimestamp,
          documentBillingRunId: activeBillingRunId,
        }),
      );
    }
  }

  const isSaleyardAdmin = getIsSaleyardAdmin(state);
  const isLivestockAgent = getIsLivestockAgent(state);

  const hasLivestockAgentRuleBookPermission = isLivestockAgent
    ? hasPermission(
        getDeploymentById(getEffectiveDeploymentId(state))(state),
        DeploymentPermissions.featureRuleBook,
      )
    : false;

  const hasSaleyardAdminRuleBookPermission = isSaleyardAdmin
    ? hasPermission(
        selectCurrentSaleyard(state),
        SaleyardPermissions.featureRuleBook,
      )
    : false;
  const hasRuleBookPermission =
    hasLivestockAgentRuleBookPermission || hasSaleyardAdminRuleBookPermission;

  if (hasRuleBookPermission) {
    if (!getIsFetchingMasterRuleBooks(state)) {
      yield put(
        MasterRuleBookAction.requestChanges({
          changesSince: state.masterRuleBooks.lastModifiedTimestamp,
        }),
      );
    }

    if (!getIsFetchingMasterRules(state)) {
      yield put(
        MasterRuleAction.requestChanges({
          changesSince: state.masterRules.lastModifiedTimestamp,
        }),
      );
    }

    if (!getIsFetchingRuleBooks(state)) {
      yield put(
        RuleBookAction.requestChanges({
          changesSince: state.ruleBooks.lastModifiedTimestamp,
        }),
      );
    }

    if (!getIsFetchingRules(state)) {
      yield put(
        RuleAction.requestChanges({
          changesSince: state.rules.lastModifiedTimestamp,
        }),
      );
    }
  }

  yield put(
    IntegrationCredentialAction.requestChanges({
      changesSince: state.integrationCredentials.changesSince,
    }),
  );
  if (!getIsFetchingLedgerAccounts(state)) {
    yield put(
      LedgerAccountAction.requestChanges({
        changesSince: state.ledgerAccounts.changesSince,
      }),
    );
  }
  if (!getIsFetchingMasterLedgerAccounts(state)) {
    yield put(
      MasterLedgerAccountAction.requestChanges({
        changesSince: state.masterLedgerAccounts.changesSince,
      }),
    );
  }
}

export default function* livestockSaleDataSaga() {
  // There is a hard to trace possibility of over-calling this - likely based on a re-re-re-re render around
  // updateStore.  This results in heartbeat firing a few dozen times.
  // It's pretty unlikely we'd want to call this multiple times a second, so, don't.
  yield debounce(1000, GET_LIVESTOCK_SALE_DATA_CHANGES, fetchLivestockSaleData);
}
