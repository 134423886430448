import React, { useCallback } from "react";

import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import {
  CircularProgress,
  Paper,
  Step,
  StepButton,
  StepLabel,
  Stepper,
} from "@material-ui/core";
import { uniqBy } from "lodash";
import { readString } from "react-papaparse";
import { useDispatch, useSelector } from "react-redux";

import { importAuctionsPlusCSV } from "actions";

import { BusinessImporter } from "components/Importer/Businesses";
import { auctionsPlusCSVBusinessColumns } from "components/Importer/columnDefinitions";
import { UploadIcon } from "components/Importer/components";
import { SessionStorageFileImportField } from "components/Importer/FileImportField";
import { LotImporter } from "components/Importer/Lots";
import WaitForSync from "components/LoadingSpinner/WaitForSync";

import {
  AUCTIONS_PLUS_CSV_FILENAME_KEY,
  AUCTIONS_PLUS_CSV_STORAGE_KEY,
} from "constants/importer";
import { ApiModel } from "constants/loading";

import {
  selectBusinessIdByAuctionsPlusCodeLookup,
  selectSaleLotIdByLotNumberLookup,
} from "selectors";

export const AuctionsPlusImporter = () => {
  return (
    <WaitForSync
      requiredData={[ApiModel.DEPLOYMENTS, ApiModel.AGENCIES, ApiModel.ROUNDS]}
    >
      <CSVImporter />
    </WaitForSync>
  );
};

// A+ CSV has headers like "AssessmentNumber " and "HighBidderLat", with and without spaces.
function trimHeaderSpaces(header) {
  return header.trim();
}

function trimRowValues(row) {
  Object.keys(row).forEach(k => {
    if (row[k] && typeof row[k] === "string") {
      row[k] = row[k].trim();
    }
  });
  return row;
}

function CSVFileImportField({ setImportData }) {
  const processCSV = useCallback(
    data => {
      const csvData = readString(data, {
        header: true,
        transformHeader: trimHeaderSpaces,
        skipEmptyLines: true,
      });
      //
      // "Age ": ""
      // AgeRange: ""
      // "AssessmentNumber ": "0"
      // "BidPrice ": "1650"
      // "DeliveryRegion ": ""
      // "DeliveryTown ": ""
      // "DressedWeight ": ""
      // "EarliestDrop ": ""
      // "HighBidderAddress ": "2 Henleydale Drive, MOOROOPNA, VIC, 3629, AU"
      // "HighBidderEmail ": "robert.bruns@nutrien.com.au"
      // "HighBidderFullName ": "Robert Bruns"
      // HighBidderLat: "-36.3929"
      // HighBidderLong: "145.364"
      // "HighBidderPhone ": "457830779"
      // "HighBidderState ": "VIC"
      // "HighBidderTown ": "MOOROOPNA"
      // "HighBidderTradingName ": "Robert Bruns"
      // "InWitholdingOrESI ": "FALSE"
      // "LatestDrop ": ""
      // "LiveWeight ": ""
      // "LotBestDescription ": " Briggs    Requires some fittings prior to work."
      // "LotIdentifier ": "Lot 22"
      // "LotNumber ": "22"
      // "LotStatus ": "Passed In"
      // "MajorityBreed ": ""
      // "MajorityBreedPercentage ": "0"
      // "MajorityFat ": ""
      // "MajorityFatPercentage ": "0"
      // "NumberOfHead ": "1"
      // "ReservePrice ": "2000"
      // "Sex ": ""
      // "SoldType ": ""
      // "StartPrice ": "1500"
      // "StockCategory ": "Manufacturing"

      setImportData({
        businesses: uniqBy(
          csvData.data,
          r => r.HighBidderCompanyUserId || r.AuctionsPlusUserID,
        )
          .map(row => {
            const addressRoutePart = row.HighBidderAddress.split(",")[0];
            const routeAddressMatch =
              /^([/\\A-Za-z-]*\d[\d/\\A-Za-z-]*)?(.*)/.exec(addressRoutePart);
            const streetNumber = routeAddressMatch[1] || "";
            const route = routeAddressMatch[2].trim();
            return {
              auctionsPlusId:
                row.HighBidderCompanyUserId || row.AuctionsPlusUserID,
              businessName: row.HighBidderTradingName,
              phoneNumber: row.HighBidderPhone,
              contactFullName: row.HighBidderFullName,
              email: row.HighBidderEmail,
              route,
              streetNumber,
              addressingInformation: row.HighBidderAddress,
              latitude: row.HighBidderLat,
              longitude: row.HighBidderLong,
              state: row.HighBidderState,
              locality: row.HighBidderTown,
            };
          })
          .map(trimRowValues),

        lots: csvData.data
          .map(row => ({
            bidPrice: row.BidPrice,
            description: row.LotBestDescription,
            category: row.StockCategory,
            // Sometimes the import lot numbers have leading zeros, we want to clear them
            // but also allow for no lot number.
            number: row.LotNumber ? parseInt(row.LotNumber, 10).toString() : "",
            status: row.LotStatus,
            quantity: row.NumberOfHead,
            reservePrice: row.ReservePrice,
            startingBid: row.StartPrice,
            auctionsPlusId:
              row.HighBidderCompanyUserId || row.AuctionsPlusUserID,
            // We need these high bidder pieces of information to add to the notes.
            businessName: row.HighBidderTradingName,
            phoneNumber: row.HighBidderPhone,
            fullName: row.HighBidderFullName,
            email: row.HighBidderEmail,
          }))
          .map(trimRowValues),
      });
    },
    [setImportData],
  );
  return (
    <SessionStorageFileImportField
      accept={["text/csv", "application/csv", ".csv"]}
      storageKey={AUCTIONS_PLUS_CSV_STORAGE_KEY}
      storageFilenameKey={AUCTIONS_PLUS_CSV_FILENAME_KEY}
      processFile={processCSV}
      fileReadyToast="The following businesses need to be created. Please double check before proceeding with the import."
      fileDescription="AuctionsPlus Results CSV"
    />
  );
}

function CSVImporter() {
  const [importData, setImportData] = React.useState({
    businesses: [],
    lots: [],
  });

  if (importData.businesses.length === 0) {
    return <CSVFileImportField setImportData={setImportData} />;
  }

  return <Importer importData={importData} setImportData={setImportData} />;
}

function Importer({ importData, setImportData }) {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [importerRunning, setImporterRunning] = React.useState(false);

  const businessIdsByAuctionsPlusCodeLookup = useSelector(
    selectBusinessIdByAuctionsPlusCodeLookup,
  );

  const saleLotIdsByLotNumberLookup = useSelector(
    selectSaleLotIdByLotNumberLookup,
  );
  const dispatch = useDispatch();

  const handleUpdateLots = () => {
    // Iterate through the import data, and if we have a matched up lot number, that's the target lot.
    setImporterRunning(true);
    const payload = importData.lots
      .filter(importLot => saleLotIdsByLotNumberLookup[importLot.number])
      .map(importLot => {
        const saleLotId = saleLotIdsByLotNumberLookup[importLot.number];
        const {
          auctionsPlusId,
          bidPrice = 0,
          reservePrice,
          startingBid,
          status,
        } = importLot;
        const reservePriceCents = reservePrice * 100;
        const startingBidCents = startingBid * 100;
        const patch = {
          clearing_sale_attributes: { status },
          reserve_price: reservePriceCents,
          starting_bid: startingBidCents,
        };
        const buyerId = businessIdsByAuctionsPlusCodeLookup[auctionsPlusId];

        // The import is the source of truth
        if (buyerId) {
          // if we have a buyer and a price set them
          patch.buyer_id = buyerId;
          patch.total_price_cents = bidPrice * 100;
        } else {
          // otherwise clear them
          patch.buyer_id = null;
          patch.total_price_cents = 0;
        }

        return {
          id: saleLotId,
          ...patch,
        };
      });
    dispatch(importAuctionsPlusCSV(payload));
  };

  const getProgress = (mapped, total) => `${mapped} / ${total}`;
  const mappedCounts = {
    totalBusinesses: importData.businesses.length,
    mappedBusinesses: importData.businesses.filter(
      importBusiness =>
        businessIdsByAuctionsPlusCodeLookup[importBusiness.auctionsPlusId],
    ).length,
    totalLots: importData.lots.length,
    mappedLots: importData.lots.filter(
      importLot => saleLotIdsByLotNumberLookup[importLot.number],
    ).length,
  };
  const completed = {
    businesses: mappedCounts.totalBusinesses === mappedCounts.mappedBusinesses,
    lots: mappedCounts.totalLots === mappedCounts.mappedLots,
  };
  const importEnabled = completed.businesses;

  return (
    <>
      <Paper square>
        <CSVFileImportField setImportData={setImportData} />

        <Stepper nonLinear activeStep={selectedTab} alternativeLabel>
          <Step key={0}>
            <StepButton
              optional={getProgress(
                mappedCounts.mappedBusinesses,
                mappedCounts.totalBusinesses,
              )}
              onClick={() => setSelectedTab(0)}
              completed={completed.businesses}
            >
              Map Businesses
            </StepButton>
          </Step>

          <Step key={1}>
            <StepButton
              optional={getProgress(
                mappedCounts.mappedLots,
                mappedCounts.totalLots,
              )}
              onClick={() => setSelectedTab(1)}
              completed={completed.lots}
            >
              Map Lots
            </StepButton>
          </Step>
          <Step key={2}>
            {importEnabled ? (
              <StepButton
                icon={
                  importerRunning ? (
                    <CircularProgress />
                  ) : (
                    <UploadIcon icon={faCloudUploadAlt} />
                  )
                }
                onClick={importerRunning ? null : handleUpdateLots}
              >
                Import {importerRunning ? "In Progress" : ""}
              </StepButton>
            ) : (
              <StepLabel error optional="Complete Business Mapping">
                Import
              </StepLabel>
            )}
          </Step>
        </Stepper>
      </Paper>
      {selectedTab === 0 && (
        <BusinessImporter
          importBusinesses={importData.businesses}
          columns={auctionsPlusCSVBusinessColumns}
          agrinousBusinessLookupSelector={
            selectBusinessIdByAuctionsPlusCodeLookup
          }
          lookupKey="auctionsPlusId"
        />
      )}
      {selectedTab === 1 && <LotImporter importLots={importData.lots} />}
    </>
  );
}
