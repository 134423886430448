import { combineReducers } from "redux";

import { CLEAR_LOCAL_STATE } from "constants/actionTypes";

import ledgerAccounts from "reducers/ledgerAccounts";
import masterLedgerAccounts from "reducers/masterLedgerAccounts";
import sundryTemplates from "reducers/sundryTemplates";

import agencies from "./agencies";
import auctionPens from "./auctionPens";
import auditLog from "./auditLog";
import auth from "./auth";
import bidderRegistrations from "./bidderRegistrations";
import billingData from "./billingData";
import billingDocuments from "./billingDocuments";
import billingRuns from "./billingRuns";
import billingTags from "./billingTags";
import businessesV2 from "./businessesV2";
import carrierCharges from "./carrierCharges";
import checkpoints from "./checkpoints";
import comments from "./comments";
import consignments from "./consignments";
import contactBillingDocuments from "./contactBillingDocuments";
import deploymentAttributes from "./deploymentAttributes";
import deployments from "./deployments";
import draftingInformation from "./draftingInformation";
import dressingRanges from "./dressingRanges";
import eContracts from "./eContracts";
import email from "./email";
import envdDocuments from "./envd";
import files from "./files";
import globalBusinesses from "./globalBusinesses";
import importers from "./importers";
import integrationBusinesses from "./integrationBusinesses";
import integrationCredentials from "./integrationCredentials";
import interestSettings from "./interest";
import ledgerEntries from "./ledgerEntries";
import livestockAgents from "./livestockAgents";
import manualAdjustments from "./manualAdjustments";
import masterLabels from "./masterLabels";
import modals from "./modals";
import nlisFiles from "./nlisFiles";
import nlisMessageGroups from "./nlisMessageGroups";
import nominations from "./nominations";
import nominationTerms from "./nominationTerms";
import offlineTemp from "./offlineTemp";
import payments from "./payments";
import penArchetypes from "./penArchetypes";
import penScanLots from "./penScanLots";
import permissionGroups from "./permissionGroups";
import products from "./products";
import properties from "./properties";
import receivalLots from "./receivalLots";
import reportJobs from "./reportJobs";
import reports from "./reports";
import {
  masterRuleBookReducer as masterRuleBooks,
  ruleBookReducer as ruleBooks,
} from "./ruleBooks";
import {
  masterRuleReducer as masterRules,
  ruleReducer as rules,
} from "./rules";
import saleDefinitions from "./saleDefinitions";
import saleLots from "./saleLots";
import sales from "./sales";
import saleSubTypes from "./saleSubTypes";
import saleyardAdmins from "./saleyardAdmins";
import saleyards from "./saleyards";
import savedViews from "./savedViews";
import scanners from "./scanners";
import screen from "./screen";
import settings from "./settings";
import singleWeighs from "./singleWeighs";
import socket from "./socket";
import speciesAttributes from "./speciesAttributes";
import synced from "./synced";
import system from "./system";
import tradingTerms from "./tradingTerms";
import userLevels from "./userLevels";
import vendorCommissionBands from "./vendorCommissionBands";
import vendorSplits from "./vendorSplits";
import weighLots from "./weighLots";
import weighLotScans from "./weighLotScans";
import weightRanges from "./weightRanges";

const appReducer = combineReducers({
  agencies,
  auctionPens,
  auditLog,
  auth,
  bidderRegistrations,
  billingData,
  billingDocuments,
  billingRuns,
  billingTags,
  businessesV2,
  carrierCharges,
  comments,
  consignments,
  contactBillingDocuments,
  checkpoints,
  deploymentAttributes,
  deployments,
  draftingInformation,
  dressingRanges,
  eContracts,
  email,
  envdDocuments,
  files,
  globalBusinesses,
  importers,
  integrationBusinesses,
  integrationCredentials,
  interestSettings,
  ledgerEntries,
  livestockAgents,
  ledgerAccounts,
  manualAdjustments,
  masterLabels,
  masterLedgerAccounts,
  masterRuleBooks,
  masterRules,
  modals,
  nlisFiles,
  nlisMessageGroups,
  nominations,
  nominationTerms,
  offlineTemp,
  payments,
  penArchetypes,
  permissionGroups,
  products,
  properties,
  penScanLots,
  receivalLots,
  reports,
  reportJobs,
  ruleBooks,
  rules,
  saleDefinitions,
  saleLots,
  sales,
  saleyardAdmins,
  saleyards,
  savedViews,
  saleSubTypes,
  scanners,
  screen,
  settings,
  singleWeighs,
  socket,
  speciesAttributes,
  sundryTemplates,
  synced,
  system,
  tradingTerms,
  userLevels,
  vendorCommissionBands,
  vendorSplits,
  weightRanges,
  weighLots,
  weighLotScans,
});

const rootReducer = (state, action) => {
  // Clear out this data on failure or success - just AFTER trying to tell the server.
  if (action.type === CLEAR_LOCAL_STATE) {
    // Local Storage wipe
    Object.keys(state).forEach(key => {
      localStorage.removeItem(`persist:${key}`);
      sessionStorage.clear();
    });
    // Service worker hose
    try {
      caches.keys().then(names => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    } catch (e) {
      /* do nothing */
    }
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
