export const SundryTemplateLabel = {
  RCTI_DEPLOYMENT: "RCTI_DEPLOYMENT",
  INVOICE_DEPLOYMENT: "INVOICE_DEPLOYMENT",
  DEPLOYMENT_RCTI: "DEPLOYMENT_RCTI",
  DEPLOYMENT_INVOICE: "DEPLOYMENT_INVOICE",
  RCTI_INVOICE: "RCTI_INVOICE",
  INVOICE_RCTI: "INVOICE_RCTI",
  RCTI_RCTI: "RCTI_RCTI",
  INVOICE_INVOICE: "INVOICE_INVOICE",
};

export const MANUAL_TEMPLATE_NAME = "Manual";
export const MANUAL_TEMPLATE_ID = -1;
