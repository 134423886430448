import { buildDeserializer, buildSerializer } from "lib/serializerUtils";

export const sundryTemplateSerializerMap = {
  id: "id",
  glCode: "gl_code",
  gstMethod: "gst_method",
  label: "label",
  name: "name",
  note: "note",
  paidFromBusinessId: "paid_from_business_id",
  paidFromSearchFilter: "paid_from_search_filter",
  paidFromType: "paid_from_type",
  paidToBusinessId: "paid_to_business_id",
  paidToSearchFilter: "paid_to_search_filter",
  paidToType: "paid_to_type",
  taxType: "tax_type",
};

export const serializeSundryTemplate = buildSerializer(
  sundryTemplateSerializerMap,
  "sundryTemplate",
);

export const deserializeSundryTemplate = buildDeserializer(
  sundryTemplateSerializerMap,
  "sundryTemplate",
);
