import "normalize.css";

import { createGlobalStyle } from "styled-components";

import { selectStyles } from "components/SearchableSelector/ReactSelect";

const compatibleStyles = `
/* CSS classes inspired by tailwind  */

/* Flex */

.flex {
  display: flex;
}

.flex-1 {
  flex: 1 1 0%;
}

/* https://tailwindcss.com/docs/font-weight */
.font-thin {
  font-weight: 100;
}

.font-extralight {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
.rounded-none {
  border-radius: 0px;
}
.rounded-sm {
  border-radius: 0.125rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-md {
  border-radius: 0.375rem;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-xl {
  border-radius: 0.75rem;
}
.rounded-2xl {
  border-radius: 1rem;
}
.rounded-3xl {
  border-radius: 1.5rem;
}
.rounded-full {
  border-radius: 9999px;
}

/* Padding */
.p-0 {
  padding: 0px;
}
.p-px {
  padding: 1px;
}
.p-0.5 {
  padding: 0.125rem;
}
.p-1 {
  padding: 0.25rem;
}
.p-1.5 {
  padding: 0.375rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-2.5 {
  padding: 0.625rem;
}
.p-3 {
  padding: 0.75rem;
}
.p-3.5 {
  padding: 0.875rem;
}
.p-4 {
  padding: 1rem;
}
.p-5 {
  padding: 1.25rem;
}
.p-6 {
  padding: 1.5rem;
}
.p-7 {
  padding: 1.75rem;
}
.p-8 {
  padding: 2rem;
}
.pt-1 {
  padding-top: 0.25rem;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.px-px {
  padding-left: 1px;
  padding-right: 1px;
}
.px-0.5 {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-1.5 {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-2.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-3.5 {
  padding-left: 0.875rem;
  padding-right: 0.875rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}
.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}
.py-0.5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-1.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-2.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-3.5 {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}
.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

/* Background Colors (sic) */

.bg-transparent {
  background-color: transparent;
}

/* Border Styles */

.border-bottom-black {
  border-bottom: 1px solid black;
}

.border-r-1 {
  border-right: 1px solid black;
}

.border-b-1 {
  border-bottom: 1px solid black;
}

.border-solid {
  border-style: solid;
}
.border-dashed {
  border-style: dashed;
}
.border-dotted {
  border-style: dotted;
}
.border-double {
  border-style: double;
}
.border-none {
  border-style: none;
}

/* Border Sizing */

.border-0 {
  border-width: 0px;
}
.border-2 {
  border-width: 2px;
}
.border-4 {
  border-width: 4px;
}
.border-8 {
  border-width: 8px;
}
.border {
  border-width: 1px;
}
.border-x-0 {
  border-left-width: 0px;
  border-right-width: 0px;
}
.border-x-2 {
  border-left-width: 2px;
  border-right-width: 2px;
}
.border-x-4 {
  border-left-width: 4px;
  border-right-width: 4px;
}
.border-x-8 {
  border-left-width: 8px;
  border-right-width: 8px;
}
.border-x {
  border-left-width: 1px;
  border-right-width: 1px;
}
.border-y-0 {
  border-top-width: 0px;
  border-bottom-width: 0px;
}
.border-y-2 {
  border-top-width: 2px;
  border-bottom-width: 2px;
}
.border-y-4 {
  border-top-width: 4px;
  border-bottom-width: 4px;
}
.border-y-8 {
  border-top-width: 8px;
  border-bottom-width: 8px;
}
.border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.border-t-0 {
  border-top-width: 0px;
}
.border-t-2 {
  border-top-width: 2px;
}
.border-t-4 {
  border-top-width: 4px;
}
.border-t-8 {
  border-top-width: 8px;
}
.border-t {
  border-top-width: 1px;
}
.border-r-0 {
  border-right-width: 0px;
}
.border-r-2 {
  border-right-width: 2px;
}
.border-r-4 {
  border-right-width: 4px;
}
.border-r-8 {
  border-right-width: 8px;
}
.border-r {
  border-right-width: 1px;
}
.border-b-0 {
  border-bottom-width: 0px;
}
.border-b-2 {
  border-bottom-width: 2px;
}
.border-b-4 {
  border-bottom-width: 4px;
}
.border-b-8 {
  border-bottom-width: 8px;
}
.border-b {
  border-bottom-width: 1px;
}
.border-l-0 {
  border-left-width: 0px;
}
.border-l-2 {
  border-left-width: 2px;
}
.border-l-4 {
  border-left-width: 4px;
}
.border-l-8 {
  border-left-width: 8px;
}
.border-l {
  border-left-width: 1px;
}

/* Sizing */
.h-full {
  height: 100%;
}
.w-full {
  width: 100%;
}

.h-75 {
  height: 75%
}


/* Alignments */
.items-start { 
  align-items: flex-start; 
}
.items-end { 
  align-items: flex-end; 
}
.items-center { 
  align-items: center; 
} 
.items-baseline { 
  align-items: baseline; 
} 
.items-stretch { 
  align-items: stretch; 
} 

.justify-start  { 
  justify-content: flex-start; 
} 
.justify-end  { 
  justify-content: flex-end; 
} 
.justify-center  { 
  justify-content: center; 
  } 
.justify-between  { 
  justify-content: space-between; 
} 
.justify-around  { 
  justify-content: space-around; 
} 
.justify-evenly  { 
  justify-content: space-evenly; 
} 



/* Font Families */

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

/* Font Style */

.italic {
  font-style: italic;
}

/* Cursors */

.cursor-pointer {
  cursor: pointer;
 }

/* Margins */
.m-12 {
  margin: 12px;
}

.m-20 {
  margin: 20px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-12{
  margin-top: 12px;
}

/* line-height */
.leading-3 {
  line-height: .75rem;
}

.leading-4 {
  line-height: 1rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
	line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-9 {
  line-height: 2.25rem; 
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.leading-snug {
  line-height: 1.375;
}

.leading-normal {
  line-height: 1.5;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-loose {
  line-height: 2;
}

/* Text Transform */
.capitalize {
  text-transform: capitalize;
}

/* Text Sizes */
.text-lg	{
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */
}

/* White Space */
.whitespace-nowrap {
  white-space: nowrap;
}

/* Max Heights */
.max-h-72{
  max-height: 18rem;
}

/* Opacity */
.opacity-30{
  opacity: 0.30;
}

/* Overflow */
.overflow-scroll{
  overflow: scroll;
}

/* Position */
.absolute {
  position: absolute;
}

.bottom-0 {
  bottom: 0;
}

/* Background */
.bg-white {
  background: white;
}
`;

export const globalAgGridStyles = `
// ensures text in cells is vertically aligned
.ag-cell-wrapper {
  height: 100%;
}

// Update ag-grid global variables for mobile (using the same width as useIsMobile() hook)
@media only screen and (max-width: 768px) {
  // Create cell spacing a bit bigger for fat fingers
  .ag-theme-balham {
    --ag-grid-size: 6px !important;
    --ag-header-height: 40px !important;
    --ag-cell-horizontal-padding: 12px !important;
    // set the custom panel (quick create, etc.) to be full width on mobile
    --ag-side-bar-panel-width: 100% !important;
  }
}
`;

export const GlobalStyles = createGlobalStyle(
  ({ theme: { fonts, fontSizes, colors, baseLineHeight, space } }) => `

  html { scroll-behavior: smooth };
  
  html, body, #root { min-height: 100vh; margin: 0; }

  .safari_only .ag-root-wrapper-body.ag-layout-normal {
    height: 100%;
  }

  .ag-react-container {
    width: 100%;
  }

  /* Makes ag-grid appear on safari and ios devices */
  _::-webkit-full-page-media, _:future, :root .safari_only   {
    height:100%;
  }

  @supports (-webkit-overflow-scrolling: touch)   {
    height:100%;
  }

  .ag-side-buttons .ag-selected button.ag-side-button-button {
    background: ${colors.primaryHighlight};
    color: ${colors.white};
  }

  div.ag-column-select-list .ag-checkbox-input-wrapper.ag-disabled {
    background: ${colors.red};
  }

  body {
    font-family: ${fonts[0]};
    font-size: ${fontSizes.beta}px;
    line-height: ${baseLineHeight};
    color: ${colors.gray40};
    background-color: ${colors.gray95};
    overscroll-behavior: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    box-sizing: border-box;
  }
  
  .ReactTable * {
    box-shadow: none !important;
    border: none !important;
    outline: none !important;
  }

  .rthfc .rt-tr-group .rt-tr-group:nth-child(2) .rt-td {
    box-shadow: inset 0px 6px 4px -4px rgba(0,0,0,0.1) !important;
  }

  .rthfc .rt-tr-group .rt-tr-group:last-child .rt-td {
    box-shadow: inset 0px -6px 4px -4px rgba(0,0,0,0.1) !important;
  }

  /* Without the plugin, the style could use the row. Inset shadow on grouped rows */
  /*
  .rt-tr-group .rt-tr-group:nth-child(2) {
    box-shadow: inset 0px 2px 4px 0 rgba(0,0,0,0.1) !important;
  }
  .rt-tr-group .rt-tr-group:last-child {
    box-shadow: inset 0px -2px 4px 0 rgba(0,0,0,0.1) !important;
  }
  .rt-tr-group .rt-tr-group:nth-child(2):last-child {
    box-shadow: inset 0px 2px 4px 0 rgba(0,0,0,0.1), inset 0px -2px 4px 0 rgba(0,0,0,0.1) !important;
  }
  */

  /* inset color on grouped rows that are not striped */
  .rt-tr-group .rt-tr-group:not(:first-child),
  .rthfc .rt-tr-group .rt-tr-group:not(:first-child) .rt-td {
    background-color: #fafafa;
  }

  .rthfc.-highlight .rt-tr:hover .rt-td {
    /* For the highlight we need a solid color, that way don't overlap the transparencies with the fixed columns*/
    background: #f3f3f3;
  }

  /* Remove the header group*/
  .rthfc .rt-thead.-headerGroups {
    display: none;
  }

  .rthfc .rt-thead.-header, .rthfc .rt-thead.-headerGroups {
    z-index: 3;
  }

  .rthfc .rt-thead.-header {
    z-index: 0;
  }

  @media only screen and (max-width: 600px) {
  /* Simple line at the top/bottom of the column header to indicate sorting. */
    .-sort-desc {
        box-shadow: inset 0 -3px 0 0 #666 !important;
    }
    .-sort-asc {
        box-shadow: inset 0 3px 0 0 #666 !important;
    }
    .ReactTable .rt-thead .rt-resizable-header {
      padding: ${space[1]}px ${space[2]}px;
    }

  }

  @media only screen and (min-width: 600px) {
    /* Styles for the up/down arrow indicating the sorting on the table */
    .ReactTable .rt-thead .rt-resizable-header {
      padding: ${space[1]}px ${space[2]}px;
    }

    .ReactTable .rt-th.-sort-asc {
      box-shadow: inset 0em 1px #427DB3 !important
    }

    .ReactTable .rt-th.-sort-desc {
      box-shadow: inset 0em -1px #427DB3 !important
    }

    /* End Styles for the up/down arrow indicating the sorting on the table */
  }

  .ReactTable .rt-th {
    text-align: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .ReactTable .right {
    text-align: right;
    justify-content: flex-end;
  }

  .ReactTable .center {
    text-align: center;
    justify-content: center;
  }


  /* Remove spacing on toast; use specific paths to force override of defaults */
  
  .Toastify .Toastify__toast-container {
      width: auto;

  }
  .Toastify .Toastify__toast-container .Toastify__toast {
      margin: 0;
      padding: 2px;
      border: 0;
      min-height: inherit;
      background: transparent;
      justify-content: flex-end;
      box-shadow: none;
  }
  .Toastify .Toastify__toast-container .Toastify__toast .Toastify__toast-body {
      margin: 0;
      padding: 0;
      box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
  }

  .Toastify__toast--success {
    min-height: 30px !important;
    height: 30px !important;
    padding: unset !important;
    margin-bottom: 10px !important;
    margin-left: -20px !important;
    overflow: inherit !important;
    bottom: 70px !important;
  }

  .Toastify__toast--success .Notification__DismissCell-sc-1s6ksy0-3.uSoSl {padding: 10px; width: 35px;}

  .Toastify__toast--success .Notification__TextCell-sc-1s6ksy0-2 {width: fit-content; padding-right: 6px;}

  .Toastify__toast--success .Notification__IconCell-sc-1s6ksy0-1 {
    padding: 10px;
    width: 30px;
  }

  /* Make sure that when dragging a selected element that its on top of whatever modal etc its in. */
  .sortableHelper {
    z-index: 9999;
  }

  .row-warning {
    background: ${colors.error} !important;
  }

  .row-yellow {
    background: ${colors.warning} !important;
  }
  
  
  ${selectStyles}
  ${compatibleStyles}
  ${globalAgGridStyles}
`,
);
