import { SundryTemplateLabel } from "constants/sundryTemplates";

const { getDollarPriceStringFromCents } = require("lib");

export const getSundryLabelByTemplateAndValue = (
  template,
  amount,
  toBusiness,
  fromBusiness,
) => {
  const { label } = template;
  const amountString = `$${getDollarPriceStringFromCents(amount)}`;

  switch (label) {
    case SundryTemplateLabel.RCTI_DEPLOYMENT:
      return `${amountString} will be ${amount > 0 ? "debited" : "credited"} to ${fromBusiness} (RCTI)`;
    case SundryTemplateLabel.DEPLOYMENT_RCTI:
      return `${amountString} will be ${amount > 0 ? "credited" : "debited"} to ${toBusiness} (RCTI)`;
    case SundryTemplateLabel.INVOICE_DEPLOYMENT:
      return `${amountString} will be ${amount > 0 ? "debited" : "credited"} to ${fromBusiness} (Invoice)`;
    case SundryTemplateLabel.DEPLOYMENT_INVOICE:
      return `${amountString} will be ${amount > 0 ? "credited" : "debited"} to ${toBusiness} (Invoice)`;
    case SundryTemplateLabel.RCTI_INVOICE:
      return `${amountString} will be ${amount > 0 ? "debited" : "credited"}  from ${fromBusiness} (RCTI) to ${toBusiness} (Invoice)`;
    case SundryTemplateLabel.INVOICE_RCTI:
      return `${amountString} will be ${amount > 0 ? "debited" : "credited"}  from ${fromBusiness} (Invoice) to ${toBusiness} (RCTI)`;
    case SundryTemplateLabel.RCTI_RCTI:
      return `${amountString} will be ${amount > 0 ? "debited" : "credited"}  from ${fromBusiness} (RCTI) to ${toBusiness} (RCTI)`;
    case SundryTemplateLabel.INVOICE_INVOICE:
      return `${amountString} will be ${amount > 0 ? "debited" : "credited"}  from ${fromBusiness} (Invoice) to ${toBusiness} (Invoice)`;
    default:
      return "";
  }
};
