import React, { useEffect } from "react";

import { Grid } from "@material-ui/core";
import { Form, Formik, getIn, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import {
  ManualAdjustmentAction,
  openDispatchingModal,
  setModalContext,
} from "actions";

import { PricingMethod } from "components/Billing/LedgerEntry/LedgerEntryPricingForm";
import { ConfirmDialog, createModalTitle } from "components/ConfirmDialog";
import { Button, DeleteButton, SecondaryButton } from "components/Form";
import { ManualChargeLabelSelectorField } from "components/Form/Fields";
import {
  CheckBox,
  SelectField,
  TextArea,
} from "components/Form/FormikControls";
import { FormikAwareBusinessPICSelector } from "components/Form/FormikControls/BusinessPICSelector";
import { HelpText } from "components/Form/FormikControls/HelpText";
import { Row } from "components/Layout";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  ZoomyDialog,
} from "components/MaterialDialog";
import { SundryPricingForm } from "components/Sundry/Form/SundryPricingForm";
import { SmallText } from "components/Text";

import { BaseTaxTypeOptions, GstMethod } from "constants/billing";
import {
  BUYER,
  DEPLOYMENT,
  LIVESTOCK_AGENT,
  TRANSPORTER,
  VENDOR,
} from "constants/businesses";
import { BusinessModalSection, ModalTypes } from "constants/navigation";
import {
  MANUAL_TEMPLATE_ID,
  SundryTemplateLabel,
} from "constants/sundryTemplates";

import { EMPTY_OBJECT, shallowObjectChanges } from "lib";

import { getLivestockSaleId, openModalLink } from "lib/navigation";
import { getSundryLabelByTemplateAndValue } from "lib/sundrys";

import {
  getActiveLivestockAgentDeployment,
  getBusinessById,
  getContextByModalType,
  getIsLivestockAgent,
  getManualAdjustments,
  getShouldChargeGSTByBusinessId,
  getSundryTemplates,
  selectLedgerAccountOptionsForLedgerEntryForm,
} from "selectors";

import {
  getSundryTemplateById,
  selectSundryTemplateOptions,
} from "selectors/sundryTemplate";

import { useBoolean } from "hooks";

const businessRoles = [BUYER, DEPLOYMENT, LIVESTOCK_AGENT, TRANSPORTER, VENDOR];

const validationSchema = Yup.object().shape({
  toBusinessId: Yup.string()
    .nullable()
    .required("Required")
    .test("valid", "Required", val => val !== "-1"),
  fromBusinessId: Yup.string()
    .nullable()
    .required("Required")
    .test("valid", "Required", val => val !== "-1"),
  note: Yup.string().required("Required"),
  glCode: Yup.string().when("sundryTemplateId", {
    is: value => value !== MANUAL_TEMPLATE_ID,
    then: schema => schema.required("Required!"),
    otherwise: schema => schema.nullable(),
  }),
  taxType: Yup.string().when("sundryTemplateId", {
    is: value => value !== MANUAL_TEMPLATE_ID,
    then: schema => schema.required("Required!"),
    otherwise: schema => schema.nullable(),
  }),
});

const isGstApplicableMap = {
  true: "Applicable",
  false: "Not Applicable",
  undefined: "Unknown",
  null: "Unknown",
};

function SundryForm(props) {
  const { id, onClose, readOnlyFields = [], namespace: ns = "" } = props;

  const dispatch = useDispatch();
  const { values, setFieldValue, initialValues, resetForm } =
    useFormikContext();
  const fieldValues = getIn(values, ns);

  const {
    fromBusinessId,
    toBusinessId,
    sundryTemplateId,
    totalInc,
    createAnother,
  } = fieldValues;

  const activeSundryTemplate = useSelector(
    getSundryTemplateById(sundryTemplateId),
  );
  const isManual = sundryTemplateId === MANUAL_TEMPLATE_ID;
  const { sundryTemplateId: initialSundryTemplateId } = initialValues;
  const ledgerAccountOptions = useSelector(
    selectLedgerAccountOptionsForLedgerEntryForm,
  );
  const [isShowingConfirmDelete, showConfirmDelete, hideConfirmDelete] =
    useBoolean(false);

  const shouldFromBusinessChargeGST = useSelector(
    getShouldChargeGSTByBusinessId(fromBusinessId),
  );

  const fromBusiness = useSelector(getBusinessById(fromBusinessId));
  const toBusiness = useSelector(getBusinessById(toBusinessId));

  const fromBusinessGSTStatus = isGstApplicableMap[shouldFromBusinessChargeGST];

  const onConfirmDelete = () => {
    id && dispatch(ManualAdjustmentAction.delete(id));
    onClose();
  };

  const onViewAll = () => {
    openModalLink(ModalTypes.ManualAdjustmentTable);
  };

  const returnTo = window.location.hash;
  function openEditFromBusiness() {
    dispatch(
      openDispatchingModal(ModalTypes.EditBusiness, returnTo, fieldValues, {
        businessId: fromBusinessId,
        defaultTab: BusinessModalSection.FINANCE_AND_ACCOUNTING,
      }),
    );
  }

  function openEditToBusiness() {
    dispatch(
      openDispatchingModal(ModalTypes.EditBusiness, returnTo, fieldValues, {
        businessId: toBusinessId,
        defaultTab: BusinessModalSection.FINANCE_AND_ACCOUNTING,
      }),
    );
  }

  const sundryTemplateOptions = useSelector(selectSundryTemplateOptions);

  useEffect(() => {
    if (
      sundryTemplateId &&
      !isManual &&
      initialSundryTemplateId !== sundryTemplateId
    ) {
      setFieldValue("fromBusinessId", activeSundryTemplate.paidFromBusinessId);
      setFieldValue("toBusinessId", activeSundryTemplate.paidToBusinessId);
      setFieldValue("glCode", activeSundryTemplate.glCode);
      setFieldValue("gstMethod", activeSundryTemplate.gstMethod);
      setFieldValue("taxType", activeSundryTemplate.taxType);
      setFieldValue("note", activeSundryTemplate.note);
      setFieldValue("sundryTemplateLabels", [activeSundryTemplate.label]);
    } else if (isManual && initialSundryTemplateId !== sundryTemplateId) {
      resetForm({
        values: {
          gstMethod: GstMethod.GST_INCLUSIVE,
          pricingMethod: PricingMethod.GROSS_PRICE,
          sundryTemplateId: MANUAL_TEMPLATE_ID,
          createAnother,
        },
      });
    }
  }, [
    sundryTemplateId,
    initialSundryTemplateId,
    activeSundryTemplate,
    isManual,
    setFieldValue,
    resetForm,
    createAnother,
  ]);

  const templateInUse = Object.values(SundryTemplateLabel).includes(
    activeSundryTemplate.label,
  );
  const renderHelpText =
    fromBusinessId && toBusinessId && totalInc && templateInUse;

  // On the manual adjustment we store the from business as the billed from, which is the paid to and vice versa
  const fromBusinessLabel = isManual ? "Billing From" : "Paid To";
  const toBusinessLabel = isManual ? "Billing To" : "Paid From";
  return (
    <Form>
      <DialogTitle onClose={onClose}>Sundry</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SelectField
              name="sundryTemplateId"
              options={sundryTemplateOptions}
              label="Type"
            />
          </Grid>
          {isManual && (
            <Grid item xs={12}>
              <ManualChargeLabelSelectorField
                label="Labels"
                name="labels"
                disabled={readOnlyFields.includes("labels")}
              />
            </Grid>
          )}

          <Grid container item xs={12}>
            <Grid item xs={9}>
              <FormikAwareBusinessPICSelector
                allowBusinessOnly
                businessRoles={
                  isManual && activeSundryTemplate?.paidToSearchFilter
                    ? businessRoles
                    : [activeSundryTemplate?.paidToSearchFilter]
                }
                label={fromBusinessLabel}
                businessFieldName="fromBusinessId"
                disabled={readOnlyFields.includes("fromBusinessId")}
              />
              <SmallText>GST Status - {fromBusinessGSTStatus}</SmallText>
            </Grid>
            <Grid item xs={3} container alignItems="center">
              <SecondaryButton
                type="button"
                onClick={openEditFromBusiness}
                disabled={!fromBusinessId}
              >
                Edit Business
              </SecondaryButton>
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={9}>
              <FormikAwareBusinessPICSelector
                allowBusinessOnly
                businessRoles={
                  isManual && activeSundryTemplate?.paidFromSearchFilter
                    ? businessRoles
                    : [activeSundryTemplate?.paidFromSearchFilter]
                }
                label={toBusinessLabel}
                businessFieldName="toBusinessId"
                disabled={readOnlyFields.includes("toBusinessId")}
              />
              <SmallText>&nbsp;</SmallText>
            </Grid>
            <Grid item xs={3} container alignItems="center">
              <SecondaryButton
                type="button"
                onClick={openEditToBusiness}
                disabled={!toBusinessId}
              >
                Edit Business
              </SecondaryButton>
            </Grid>
          </Grid>

          <SundryPricingForm readOnlyFields={readOnlyFields} />
          {renderHelpText && (
            <Grid item xs={12}>
              <HelpText>
                {getSundryLabelByTemplateAndValue(
                  activeSundryTemplate,
                  totalInc,
                  toBusiness?.name,
                  fromBusiness?.name,
                )}
              </HelpText>
            </Grid>
          )}

          <Grid item xs={12}>
            <TextArea
              label="Description"
              name="note"
              readOnly={readOnlyFields.includes("note")}
              tooltip="This will appear on RCTIs and/or Invoices"
              required={templateInUse}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectField
              label="GL Code"
              name="glCode"
              readOnly={readOnlyFields.includes("glCode")}
              options={ledgerAccountOptions}
              menuPortalTarget={document.body}
              required={templateInUse}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectField
              label="Tax Type"
              name="taxType"
              readOnly={readOnlyFields.includes("taxType")}
              options={BaseTaxTypeOptions}
              menuPortalTarget={document.body}
              required={templateInUse}
            />
          </Grid>
        </Grid>
        <Row justifyStart alignEnd>
          <CheckBox name="createAnother" label="Add Another On Submit" />
        </Row>
      </DialogContent>
      <DialogActions>
        {id && (
          <DeleteButton type="button" onClick={showConfirmDelete}>
            Delete
          </DeleteButton>
        )}
        <SecondaryButton type="button" onClick={onViewAll}>
          View All
        </SecondaryButton>

        <SecondaryButton type="button" onClick={onClose}>
          Cancel
        </SecondaryButton>
        <Button data-tour="adjust" type="submit">
          Submit
        </Button>
      </DialogActions>
      <ConfirmDialog
        title={createModalTitle("this Sundry")}
        isOpen={isShowingConfirmDelete}
        onCancel={hideConfirmDelete}
        onDelete={onConfirmDelete}
      />
    </Form>
  );
}

export const Modal = ({
  id,
  fromBusinessId,
  toBusinessId,
  labels,
  gstMethod,
  readOnlyFields,
  note = "",
  totalAmountCents = undefined,
  onClose,
}) => {
  const defaults = {
    fromBusinessId,
    toBusinessId,
    labels,
    note,
    totalAmountCents,
    gstMethod: gstMethod || GstMethod.GST_INCLUSIVE,
    pricingMethod: PricingMethod.GROSS_PRICE,
  };
  const handleClose = () => {
    dispatch(setModalContext(ModalTypes.EditBusiness, null));
    onClose();
  };

  const manualAdjustment =
    useSelector(getManualAdjustments)?.[id] || EMPTY_OBJECT;

  const context =
    useSelector(getContextByModalType(ModalTypes.EditBusiness)) || {};
  const initialValues = {
    ...defaults,
    // Variable names danced so that we can make use shared common in usePricingFormContext.
    taxAmount: manualAdjustment.gstCents,
    subtotal: manualAdjustment.subtotalCents,
    totalInc: manualAdjustment.totalCents,
    ...manualAdjustment,
    sundryTemplateId: manualAdjustment.sundryTemplateId || MANUAL_TEMPLATE_ID,
    ...context,
  };

  const deploymentId = useSelector(state =>
    getIsLivestockAgent(state)
      ? getActiveLivestockAgentDeployment(state).id
      : null,
  );

  const dispatch = useDispatch();

  const sundryTemplates = useSelector(getSundryTemplates);

  const onSubmit = (rawValues, { resetForm }) => {
    // Variable names danced so that we can make use shared common in usePricingFormContext.
    const values = {
      fromBusinessId: rawValues.fromBusinessId,
      toBusinessId: rawValues.toBusinessId,
      note: rawValues.note,
      subtotalCents: rawValues.subtotal,
      gstMethod: rawValues.gstMethod,
      gstCents: rawValues.taxAmount,
      labels: rawValues.labels,
      sundryTemplateId:
        rawValues.sundryTemplateId !== MANUAL_TEMPLATE_ID
          ? rawValues.sundryTemplateId
          : null,
      sundryTemplateLabels: rawValues.sundryTemplateLabels,
      glCode: rawValues.glCode,
      taxType: rawValues.taxType,
    };

    const { createAnother } = rawValues;

    if (id) {
      const payload = {
        id,
        ...shallowObjectChanges(values, manualAdjustment),
      };

      dispatch(ManualAdjustmentAction.update(payload));
    } else {
      const payload = {
        ...values,
        id: uuidv4(),
        livestockSaleId: getLivestockSaleId(),
        deploymentId,
      };
      dispatch(ManualAdjustmentAction.create(payload));
    }

    if (createAnother) {
      let resetValues = {
        id: null,
        createAnother: true,
        sundryTemplateId: MANUAL_TEMPLATE_ID,
        gstMethod: GstMethod.GST_INCLUSIVE,
        pricingMethod: PricingMethod.GROSS_PRICE,
      };
      if (rawValues.sundryTemplateId !== MANUAL_TEMPLATE_ID) {
        const sundryTemplate = sundryTemplates[values.sundryTemplateId];

        resetValues = {
          ...resetValues,
          sundryTemplateId: values.sundryTemplateId,
          fromBusinessId: sundryTemplate.paidFromBusinessId,
          toBusinessId: sundryTemplate.paidToBusinessId,
          glCode: sundryTemplate.glCode,
          taxType: sundryTemplate.taxType,
          note: sundryTemplate.note,
          sundryTemplateLabels: sundryTemplate.label,
          gstMethod: sundryTemplate.gstMethod || GstMethod.GST_INCLUSIVE,
        };
      }
      resetForm({ values: resetValues });
    } else {
      handleClose();
    }
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <ZoomyDialog open onClose={handleClose}>
        <SundryForm
          id={id}
          readOnlyFields={readOnlyFields}
          onClose={handleClose}
        />
      </ZoomyDialog>
    </Formik>
  );
};
