import { GstMethod } from "constants/billing";

const defaultRelationProperties = [
  { name: "business_id", type: "business" },
  { name: "percentage", type: "percentage" },
  { name: "relationship_effective_commission_percentage", type: "percentage" },
  { name: "name", type: "text" },
  {
    name: "is_gst_registered",
    set: [
      ["True", true],
      ["False", false],
    ],
    type: "boolean",
  },
];

export const LivestockSaleFieldSchema = [
  {
    id: "age",
    idAttribute: "id:int",
    path: '$["ages"]',
    singular: "Age",
    plural: "Ages",
    properties: [{ name: "name", type: "text" }],
  },
  {
    id: "animal",
    idAttribute: "id:uuid",
    path: '$["animals"]',
    singular: "Animal",
    plural: "Animals",
    relations: [
      { id: "deployment", via: "rel:deployment" },
      { id: "lot", via: "rel:lot" },
      { id: "sale", via: "rel:sale" },
    ],
    properties: [
      { name: "calculated_weight_grams", type: "weight_grams" },
      { name: "eid", type: "text" },
      {
        name: "is_weighed",
        set: [
          ["True", true],
          ["False", false],
        ],
        type: "boolean",
      },
      { name: "total_weight_grams", type: "weight_grams" },
    ],
  },
  {
    id: "breed",
    idAttribute: "id:int",
    path: '$["breeds"]',
    singular: "Breed",
    plural: "Breeds",
    properties: [{ name: "name", type: "text" }],
  },
  {
    id: "buyer",
    idAttribute: "id:auto_id",
    path: '$["buyers"]',
    singular: "Buyer",
    plural: "Buyers",
    properties: [
      { name: "business_id", type: "business" },
      { name: "name", type: "business_name" },
      { name: "buyer_way", type: "text" },
      { name: "registration_number", type: "text" },
      {
        name: "is_hobby_farmer",
        set: [
          ["True", true],
          ["False", false],
        ],
        type: "boolean",
      },
      {
        name: "is_meat_buyer",
        set: [
          ["True", true],
          ["False", false],
        ],
        type: "boolean",
      },
      {
        name: "has_debtor_insurance",
        set: [
          ["True", true],
          ["False", false],
        ],
        type: "boolean",
      },
      {
        name: "commission_offset",
        type: "currency_cents",
      },
      {
        name: "has_transit_insurance",
        set: [
          ["True", true],
          ["False", false],
        ],
        type: "boolean",
      },

      {
        name: "should_charge_gst",
        set: [
          ["True", true],
          ["False", false],
        ],
        type: "gst_applicability_boolean",
      },
    ],
    relations: [
      { id: "deployment", via: "rel:deployment" },
      { id: "sale", via: "rel:sale" },
      { id: "buyer_property", via: "rel:buyer_property" },
    ],
    manyRelations: [
      {
        id: "buyers_purchase_rebate_agent",
        via: "buyers_purchase_rebate_agents",
        name: "Buyer's Purchase Rebate Agents",
      },
    ],
  },
  {
    id: "buyers_purchase_rebate_agent",
    idAttribute: "id:auto_id",
    path: '$["buyers_purchase_rebate_agents"]',
    singular: "Buyer's Purchase Rebate Agent",
    plural: "Buyer's Purchase Rebate Agents",
    properties: defaultRelationProperties,
    relations: [
      { id: "deployment", via: "rel:deployment" },
      { id: "sale", via: "rel:sale" },
    ],
    manyRelations: [
      {
        id: "buyer",
        via: "buyers_purchase_rebate_agents_buyers",
        name: "Buyers",
      },
      {
        id: "lot",
        via: "buyers_purchase_rebate_agents_lots",
        name: "Lots",
      },
    ],
  },
  {
    id: "carrier",
    idAttribute: "id:auto_id",
    path: '$["carriers"]',
    singular: "Carrier",
    plural: "Carriers",
    properties: [
      { name: "business_id", type: "business" },
      { name: "percentage", type: "percentage" },
      { name: "name", type: "text" },
      {
        name: "is_gst_registered",
        set: [
          ["True", true],
          ["False", false],
        ],
        type: "boolean",
      },
    ],
    relations: [
      { id: "deployment", via: "rel:deployment" },
      { id: "sale", via: "rel:sale" },
    ],
    manyRelations: [
      {
        id: "manual_adjustment",
        via: "to_manual_adjustment",
        name: "To Sundries",
      },
      {
        id: "manual_adjustment",
        via: "from_manual_adjustment",
        name: "From Sundries",
      },
    ],
  },
  {
    id: "consignment",
    idAttribute: "id:uuid",
    path: '$["consignments"]',
    singular: "Consignment",
    plural: "Consignments",
    properties: [{ name: "total_hd_nominated", type: "integer" }],
    relations: [
      { id: "deployment", via: "rel:deployment" },
      { id: "sale", via: "rel:sale" },
      { id: "vendor", via: "rel:vendor" },
      { id: "vendor_property", via: "rel:vendor_property" },
    ],
    manyRelations: [
      {
        id: "outside_agent",
        via: "outside_agents",
        name: "Outside Agents",
      },
      {
        id: "stock_agent",
        via: "stock_agents",
        name: "Stock Agents",
      },
      {
        id: "rebate_agent",
        via: "rebate_agents",
        name: "Rebate Agents",
      },
      {
        id: "livestock_consultant",
        via: "livestock_consultants",
        name: "Livestock Consultants",
      },
    ],
  },
  {
    id: "deployment",
    idAttribute: "id:uuid",
    path: '$["deployments"]',
    singular: "Deployment (Agency)",
    plural: "Deployments (Agencies)",
    properties: [
      { name: "business_id", type: "business" },
      {
        name: "is_gst_registered",
        set: [
          ["True", true],
          ["False", false],
        ],
        type: "boolean",
      },
      { name: "name", type: "business_name" },
      {
        name: "should_charge_gst",
        set: [
          ["True", true],
          ["False", false],
        ],
        type: "gst_applicability_boolean",
      },
      {
        name: "is_hobby_farmer",
        set: [
          ["True", true],
          ["False", false],
        ],
        type: "boolean",
      },
      {
        name: "has_debtor_insurance",
        set: [
          ["True", true],
          ["False", false],
        ],
        type: "boolean",
      },
      {
        name: "commission_offset",
        type: "currency_cents",
      },
      {
        name: "has_transit_insurance",
        set: [
          ["True", true],
          ["False", false],
        ],
        type: "boolean",
      },
      {
        name: "default_commission",
        type: "currency_cents",
      },
      {
        name: "default_debtor_insurance",
        type: "percentage",
      },
      {
        name: "default_interstate_transit_insurance",
        type: "percentage",
      },
      {
        name: "default_transit_insurance",
        type: "percentage",
      },
      {
        name: "portion_of_sale_by_quantity_exc_progeny",
        type: "percentage",
      },
      {
        name: "portion_of_sale_by_agencies_involved",
        type: "percentage",
      },
    ],
    relations: [{ id: "sale", via: "rel:sale" }],
  },
  {
    id: "lot",
    idAttribute: "id:uuid",
    path: '$["lots"]',
    singular: "Sale Lot",
    plural: "Sale Lots",
    relations: [
      { id: "age", via: "rel:age" },
      { id: "buyer", via: "rel:buyer" },
      { id: "buyer_property", via: "rel:buyer.buyer_property" },
      { id: "breed", via: "rel:breed" },
      { id: "deployment", via: "rel:deployment" },
      { id: "consignment", via: "rel:consignment" },
      { id: "vendor_property", via: "rel:vendor.vendor_property" },
      {
        id: "vendor",
        via: "rel:vendor",
      },
      { id: "round", via: "rel:round" },
      { id: "sale", via: "rel:sale" },
      { id: "sex", via: "rel:sex" },
      { id: "species", via: "rel:species" },
      { id: "yard", via: "rel:yard" },
    ],
    properties: [
      { name: "average_weight_grams", type: "weight_grams" },
      { name: "cents_per_head", type: "currency_cents" },
      { name: "commercial_category", type: "text" },
      {
        name: "effective_buyers_premium_cents",
        type: "currency_cents",
      },
      {
        name: "effective_listing_fee_cents",
        type: "currency_cents",
      },
      {
        name: "effective_vendor_commission_cents",
        type: "currency_cents",
      },
      {
        name: "is_gst_exempt",
        set: [
          ["True", true],
          ["False", false],
        ],
        type: "boolean",
      },
      {
        name: "is_no_sale",
        set: [
          ["True", true],
          ["False", false],
        ],
        type: "boolean",
      },
      {
        name: "is_sold",
        set: [
          ["True", true],
          ["False", false],
        ],
        type: "boolean",
      },
      {
        name: "is_weighed",
        set: [
          ["True", true],
          ["False", false],
        ],
        type: "boolean",
      },
      {
        name: "labels",
        type: "list",
      },
      {
        name: "should_charge_gst",
        set: [
          ["True", true],
          ["False", false],
        ],
        type: "gst_applicability_boolean",
      },
      { name: "total_price_cents", type: "currency_cents" },
      { name: "total_weight_grams", type: "weight_grams" },
      {
        name: "total_hd_exc_progeny",
        type: "integer",
      },
      {
        name: "total_hd_inc_progeny",
        type: "integer",
      },
      {
        name: "total_hd_progeny",
        type: "integer",
      },
      {
        name: "total_tags_used",
        type: "integer",
      },
      { name: "pricing_type", type: "text" },
    ],
    manyRelations: [
      {
        id: "buyers_purchase_rebate_agent",
        via: "buyers_purchase_rebate_agents",
        name: "Buyer's Purchase Rebate Agents",
      },
      {
        id: "outside_agent",
        via: "outside_agents",
        name: "Outside Agents",
      },
      {
        id: "stock_agent",
        via: "stock_agents",
        name: "Stock Agents",
      },
      {
        id: "rebate_agent",
        via: "rebate_agents",
        name: "Rebate Agents",
      },
      {
        id: "livestock_consultant",
        via: "livestock_consultants",
        name: "Livestock Consultants",
      },
    ],
  },
  {
    id: "manual_adjustment_business",
    idAttribute: "id:uuid",
    path: '$["manual_adjustment_businesses"]',
    singular: "Sundry Business",
    plural: "Sundry Businesses",
    properties: [
      { name: "business_id", type: "business" },
      { name: "name", type: "text" },
      {
        name: "is_gst_registered",
        set: [
          ["True", true],
          ["False", false],
        ],
        type: "gst_applicability_boolean",
      },
    ],
    relations: [],
    manyRelations: [],
  },
  {
    id: "manual_adjustment",
    idAttribute: "id:uuid",
    path: '$["manual_adjustments"]',
    singular: "Sundry",
    plural: "Sundries",
    properties: [
      { name: "from_business_id", type: "business" },
      { name: "to_business_id", type: "business" },
      { name: "subtotal_cents", type: "currency_cents" },
      { name: "gl_code", type: "gl_code" },
      { name: "gst_cents", type: "currency_cents" },
      { name: "tax_type", type: "tax_type" },
      { name: "total_cents", type: "currency_cents" },
      { name: "labels", type: "list" },
      {
        name: "gst_method",
        set: [
          ["Fixed", GstMethod.GST_FIXED],
          ["Inclusive", GstMethod.GST_INCLUSIVE],
          ["Exclusive", GstMethod.GST_EXCLUSIVE],
          ["Exempt", GstMethod.GST_EXEMPT],
        ],
        type: "boolean",
      },
    ],
    relations: [
      { id: "yard", via: "rel:yard" },
      { id: "deployment", via: "rel:deployment" },
      {
        id: "manual_adjustment_business",
        name: "From Business",
        via: "rel:from_business",
        uniq: "from_manual_adjustment_business",
      },
      {
        id: "manual_adjustment_business",
        via: "rel:to_business",
        name: "To Business",
        uniq: "to_manual_adjustment_business",
      },
    ],
    manyRelations: [
      {
        id: "carrier",
        via: "to_manual_adjustment_carriers",
        name: "To Carriers",
      },

      {
        id: "carrier",
        via: "from_manual_adjustment_carriers",
        name: "From Carriers",
      },
    ],
  },
  {
    id: "outside_agent",
    idAttribute: "id:auto_id",
    path: '$["outside_agents"]',
    singular: "Outside Agent",
    plural: "Outside Agents",
    properties: defaultRelationProperties,
    relations: [
      { id: "deployment", via: "rel:deployment" },
      { id: "sale", via: "rel:sale" },
    ],
    manyRelations: [
      {
        id: "consignment",
        via: "outside_agents_consignments",
        name: "Consignments",
      },
      {
        id: "lot",
        via: "outside_agents_lots",
        name: "Lots",
      },
      {
        id: "vendor",
        via: "outside_agents_vendors",
        name: "Vendors",
      },
    ],
  },
  {
    id: "round",
    idAttribute: "id:int",
    path: '$["rounds"]',
    singular: "Round",
    plural: "Rounds",
    properties: [{ name: "name", type: "text" }],
    relations: [{ id: "sale", via: "rel:sale" }],
  },
  {
    id: "sale",
    idAttribute: "id:uuid",
    path: '$["sales"]',
    singular: "Sale",
    plural: "Sales",
    properties: [
      { name: "code", type: "text" },
      { name: "type", type: "text" },
      { name: "sub_type", type: "text" },
    ],
  },
  {
    id: "sex",
    idAttribute: "id:int",
    path: "$['sexes]",
    singular: "Sex",
    plural: "Sexes",
    properties: [
      {
        name: "name",
        type: "text",
      },
    ],
  },
  {
    id: "species",
    idAttribute: "id:auto_id",
    path: '$["species"]',
    singular: "Species",
    plural: "Species",
    properties: [
      {
        name: "name",
        type: "text",
      },
      {
        name: "default_pricing_type",
        type: "text",
      },
    ],
    relations: [{ id: "sale", via: "rel:sale" }],
  },

  {
    id: "stock_agent",
    idAttribute: "id:auto_id",
    path: '$["stock_agents"]',
    singular: "Stock Agent",
    plural: "Stock Agents",
    properties: defaultRelationProperties,
    relations: [
      { id: "deployment", via: "rel:deployment" },
      { id: "sale", via: "rel:sale" },
    ],
    manyRelations: [
      {
        id: "consignment",
        via: "stock_agents_consignments",
        name: "Consignments",
      },
      {
        id: "lot",
        via: "stock_agents_lots",
        name: "Lots",
      },
      {
        id: "vendor",
        via: "stock_agents_vendors",
        name: "Vendors",
      },
    ],
  },
  {
    id: "rebate_agent",
    idAttribute: "id:auto_id",
    path: '$["rebate_agents"]',
    singular: "Rebate Agent",
    plural: "Rebate Agents",
    properties: defaultRelationProperties,
    relations: [
      { id: "deployment", via: "rel:deployment" },
      { id: "sale", via: "rel:sale" },
    ],
    manyRelations: [
      {
        id: "consignment",
        via: "rebate_agents_consignments",
        name: "Consignments",
      },
      {
        id: "lot",
        via: "rebate_agents_lots",
        name: "Lots",
      },
      {
        id: "vendor",
        via: "rebate_agents_vendors",
        name: "Vendors",
      },
    ],
  },
  {
    id: "livestock_consultant",
    idAttribute: "id:auto_id",
    path: '$["livestock_consultants"]',
    singular: "Livestock Consultant",
    plural: "Livestock Consultants",
    properties: defaultRelationProperties,
    relations: [
      { id: "deployment", via: "rel:deployment" },
      { id: "sale", via: "rel:sale" },
    ],
    manyRelations: [
      {
        id: "consignment",
        via: "livestock_consultants_consignments",
        name: "Consignments",
      },
      {
        id: "lot",
        via: "livestock_consultants_lots",
        name: "Lots",
      },
      {
        id: "vendor",
        via: "livestock_consultants_vendors",
        name: "Vendors",
      },
    ],
  },
  {
    id: "vendor",
    idAttribute: "id:auto_id",
    path: '$["vendors"]',
    singular: "Vendor",
    plural: "Vendors",
    properties: [
      { name: "business_id", type: "business" },
      {
        name: "is_gst_registered",
        set: [
          ["True", true],
          ["False", false],
        ],
        type: "boolean",
      },
      { name: "name", type: "business_name" },
      {
        name: "is_hobby_farmer",
        set: [
          ["True", true],
          ["False", false],
        ],
        type: "boolean",
      },
      {
        name: "has_debtor_insurance",
        set: [
          ["True", true],
          ["False", false],
        ],
        type: "boolean",
      },
      {
        name: "commission_offset",
        type: "currency_cents",
      },
      {
        name: "effective_commission_percentage",
        type: "percentage",
      },
      {
        name: "effective_commission_per_head",
        type: "currency_cents",
      },
      {
        name: "has_transit_insurance",
        set: [
          ["True", true],
          ["False", false],
        ],
        type: "boolean",
      },
      {
        name: "should_charge_gst",
        set: [
          ["True", true],
          ["False", false],
        ],
        type: "gst_applicability_boolean",
      },
    ],
    relations: [
      { id: "deployment", via: "rel:deployment" },
      { id: "sale", via: "rel:sale" },
    ],
    manyRelations: [
      {
        id: "outside_agent",
        via: "outside_agents",
        name: "Outside Agents",
      },
      {
        id: "stock_agent",
        via: "stock_agents",
        name: "Stock Agents",
      },
      {
        id: "rebate_agent",
        via: "rebate_agents",
        name: "Rebate Agents",
      },
      {
        id: "livestock_consultant",
        via: "livestock_consultants",
        name: "Livestock Consultants",
      },
    ],
  },
  {
    id: "yard",
    idAttribute: "id:int",
    path: '$["yards"]',
    singular: "Sale Yard",
    plural: "Sale Yards",
    properties: [
      // TODO: When a saleyard has a business, update this.
      { name: "name", type: "business" },
      // Duplicate! leave it here or something breaks for some reason
      { name: "name", type: "text" },
    ],
  },
  {
    id: "vendor_property",
    idAttribute: "id:int",
    path: '$["vendor_properties"]',
    singular: "Vendor Property",
    plural: "Vendor Properties",
    properties: [
      { name: "pic", type: "text" },
      { name: "name", type: "text" },
    ],
  },
  {
    id: "buyer_property",
    idAttribute: "id:int",
    path: '$["buyer_properties"]',
    singular: "Buyer Property",
    plural: "Buyer Properties",
    properties: [
      { name: "pic", type: "text" },
      { name: "name", type: "text" },
    ],
  },
];
