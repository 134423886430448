import { eventChannel } from "redux-saga";
import {
  all,
  call,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import {
  appUpdateComplete,
  AuctionPenAction,
  BillingTagsAction,
  BusinessAction,
  CheckpointAction,
  clearNvdUploads,
  clearP2PFiles,
  clearSellFiles,
  clearSellReversals,
  clearTakeFiles,
  clearTakeReversals,
  CommentAction,
  DeploymentAction,
  DraftingInformationAction,
  fetchGeoData,
  ManualAdjustmentAction,
  MasterRuleAction,
  MasterRuleBookAction,
  NominationAction,
  PaymentAction,
  PenArchetypeAction,
  pingFromApp,
  requestAgencies,
  requestAllSellFiles,
  requestAllSellReversals,
  requestAllTakeReversals,
  requestAllTakeStatuses,
  requestAttachments,
  requestBidderRegistrations,
  requestConsignments,
  fetchLivestockSaleDataChanges,
  requestDefaultRoundsAndSaleTypes,
  requestDeploymentAttributes,
  requestProducts,
  requestProperties,
  requestScans,
  requestSpeciesAttributes,
  RuleAction,
  RuleBookAction,
  SaleAction,
  SaleLotAction,
  SaleyardAction,
  SavedViewAction,
  ServerHeartbeatAction,
  setSetting,
  SingleWeighAction,
  updateRefreshInterval,
  WeighLotAction,
  WeighLotScanAction,
} from "actions";

import { DressingRangesAction } from "actions/DressingRanges";
import { IntegrationCredentialAction } from "actions/integrationCredentials";
import { InterestSettingsAction } from "actions/interest";
import { LedgerAccountAction } from "actions/ledgerAccounts";
import { MasterLabelAction } from "actions/masterLabels";
import { MasterLedgerAccountAction } from "actions/masterLedgerAccounts";
import { NominationTermsAction } from "actions/nominationTerms";
import { PenScanLotAction } from "actions/penScanLots";
import { ReceivalLotAction } from "actions/receivalLots";
import { SaleSubTypeAction } from "actions/saleSubTypes";
import { SundryTemplateAction } from "actions/sundryTemplates";
import { TradingTermAction } from "actions/tradingTerms";
import { VendorCommissionBandAction } from "actions/vendorCommissionBands";
import {
  DefaultVendorSplitAction,
  SaleVendorSplitAction,
} from "actions/vendorSplits";
import { WeightRangesAction } from "actions/weightRanges";

import {
  API_RESPONSE,
  DEPLOYMENT,
  HUB_CONNECTED,
  HUB_DISCONNECTED,
  REFRESH_INTERVAL,
  REQUEST_LIVESTOCK_SALE_DATA,
  REQUEST_SYSTEM_DATA,
  REQUEST_USER_ROLE_DATA,
  RESTORE_SESSION,
  SALE,
  SALEYARD,
  SET_ACTIVE_ROLE,
  SET_CURRENT_LIVESTOCKSALE,
  SIMULATE_REDUX_SAGA_ERROR,
} from "constants/actionTypes";
import {
  DeploymentPermissions,
  SaleyardPermissions,
} from "constants/permissions";
import { Settings } from "constants/settings";
import { effectMap } from "constants/system";

import { clearHeartBeatInterval, heartBeatTrigger } from "lib/heartBeat";
import {
  getLivestockSaleId,
  getSaleyardName,
  redirectToFirstSaleyardFromRole,
} from "lib/navigation";
import { hasPermission } from "lib/permissions";
import { getApiSale } from "lib/sale";

import {
  getActiveLivestockAgentDeployment,
  getActiveRole,
  getIsBusinessUser,
  getIsHubConnected,
  getIsLivestockAgent,
  getIsSaleyardAdmin,
  getIsScaleOperator,
  getRefreshIntervalMs,
  getSetting,
  selectCurrentSaleyard,
} from "selectors";

function* onSimulateReduxSagaError() {
  yield;

  throw new Error("Simulated Redux Saga Error");
}

/**
 * Processes and triggers events related to when a API GET request succeeds
 * @param {{ type: string, response: Response }} action
 */
function* onApiResponseGetSuccess(action) {
  const { response } = action;
  // Process any potential changes to the heartbeat interval (only if the server has indicated that it has a preference for the request)
  // The changes since APIs should respond with a refresh interval header.
  const refreshIntervalRaw = response.headers.get("x-refresh-interval");
  if (refreshIntervalRaw) {
    const refreshIntervalMs = parseInt(refreshIntervalRaw, 10);
    const state = yield select();
    const currentRefreshIntervalMs = getRefreshIntervalMs(state);

    if (currentRefreshIntervalMs !== refreshIntervalMs) {
      yield put(updateRefreshInterval(refreshIntervalMs));
    }
  }
}

function* onApiResponseGetUpdateFailure(action) {
  const { error, action: originalAction } = action;
  const { response } = error || {};

  // TODO - document this - what are we trying to do with it?
  // The salelot number can come from two different spots, so check both and if not there return blank
  const effectType = effectMap(
    originalAction?.payload?.saleLot?.lot_number || response?.lot_number || "",
  )[originalAction.type];
  if (effectType) {
    try {
      if (error.status === 404) {
        yield put(effectType.delete(action?.meta?.offline?.commit?.meta?.id));
      }
      if (effectType && (error.status === 409 || error.status === 400)) {
        yield call(effectType.fetch, action.meta);
      }
      if (error.method === "DELETE") {
        yield call(effectType.fetch, action?.meta?.offline?.commit?.meta?.id);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }
}

function* onRequestSaleData() {
  const livestockSaleId = getLivestockSaleId();
  const saleyardName = getSaleyardName();

  const apiSale = getApiSale();

  const state = yield select();
  const isBusinessUser = getIsBusinessUser(state);
  const isScaleOperator = getIsScaleOperator(state);
  const isSaleyardAdmin = getIsSaleyardAdmin(state);
  const isLivestockAgent = getIsLivestockAgent(state);

  const hasReceivalLotPermission = hasPermission(
    selectCurrentSaleyard(state),
    SaleyardPermissions.featureReceivalLots,
  );

  const hasPenScanLotPermission = hasPermission(
    selectCurrentSaleyard(state),
    SaleyardPermissions.featurePenScanLots,
  );

  const hasWeighLotPermission = hasPermission(
    selectCurrentSaleyard(state),
    SaleyardPermissions.featureWeighLots,
  );

  const hasVendorSplitPermission = hasPermission(
    getActiveLivestockAgentDeployment(state),
    DeploymentPermissions.featurePercentageVendorSplits,
  );

  const permissionCheckActionTypes = [
    CommentAction,
    TradingTermAction,
    SundryTemplateAction,
  ];

  yield all(
    permissionCheckActionTypes
      .map(action => action.actionFilter(state, false))
      .filter(Boolean)
      .map(action => put(action)),
  );

  // If the sale has changed, get the sale dependent data.
  if (!isBusinessUser) {
    // There's a bunch of data a business doesn't need - don't get it!
    yield put(requestBidderRegistrations(saleyardName, livestockSaleId));
    yield put(requestAttachments(apiSale));
    if (hasReceivalLotPermission) {
      yield put(ReceivalLotAction.request());
    }
    if (hasPenScanLotPermission) {
      yield put(PenScanLotAction.request());
    }
    if (hasWeighLotPermission) {
      yield put(WeighLotAction.request());
      yield put(WeighLotScanAction.request());
    }
  }

  if (isScaleOperator) {
    yield put(requestAllTakeStatuses(apiSale));
    yield put(requestAllTakeReversals(apiSale));

    yield put(requestAllSellReversals(apiSale));
    yield put(requestAllSellFiles(apiSale));
  }

  if (isSaleyardAdmin || isLivestockAgent) {
    yield put(clearTakeFiles(apiSale));
    yield put(clearTakeReversals(apiSale));
    yield put(clearNvdUploads(apiSale));
    yield put(clearSellFiles(apiSale));
    yield put(clearSellReversals(apiSale));
    yield put(clearP2PFiles(apiSale));
  }

  yield put(CheckpointAction.request({ livestockSaleId }));
  yield put(SaleLotAction.request());
  yield put(requestScans(apiSale));
  yield put(AuctionPenAction.request());
  yield put(requestConsignments(apiSale));
  yield put(ManualAdjustmentAction.request());
  yield put(NominationAction.request({ livestockSaleId }));
  yield put(
    PaymentAction.request({
      livestockSalesIdIn: [livestockSaleId],
    }),
  );
  yield put(PenArchetypeAction.request());
  yield put(SingleWeighAction.request());

  if (hasVendorSplitPermission) {
    yield put(SaleVendorSplitAction.request({ livestockSaleId }));
  }
}

function* onRequestUserRoleData() {
  const state = yield select();
  const activeRole = getActiveRole(state);
  const isBusinessUser = getIsBusinessUser(state);
  const saleyardName = activeRole.saleyards?.[0].name;
  if (activeRole.slug) {
    // TODO: Refactor the businesses endpoint to just use the role (For GET)
    yield put(SaleAction.request());
    if (saleyardName && !isBusinessUser) {
      yield put(BusinessAction.request());
    }

    yield put(requestDefaultRoundsAndSaleTypes());
    yield put(fetchGeoData());
    yield put(ServerHeartbeatAction.request());
    yield put(DraftingInformationAction.request());
    yield put(SavedViewAction.request());

    yield put(requestAgencies());
    yield put(DeploymentAction.request());
    yield put(requestDeploymentAttributes());
    yield put(MasterLabelAction.request());
    yield put(requestProducts());
    yield put(SaleyardAction.request());
    yield put(NominationTermsAction.request());
    yield put(requestProperties());
    yield put(VendorCommissionBandAction.request());
    yield put(IntegrationCredentialAction.request());

    yield put(MasterRuleBookAction.request());
    yield put(MasterRuleAction.request());
    yield put(RuleBookAction.request());
    yield put(RuleAction.request());

    yield put(LedgerAccountAction.request());
    yield put(MasterLedgerAccountAction.request());
  }
}

function* onRequestDeploymentPermissionData() {
  const state = yield select();
  const isLivestockAgent = getIsLivestockAgent(state);
  const permissionCheckActionTypes = [
    InterestSettingsAction,
    DefaultVendorSplitAction,
    TradingTermAction,
    SundryTemplateAction,
  ];
  if (isLivestockAgent) {
    yield all(
      permissionCheckActionTypes
        .map(action => action.actionFilter(state, false))
        .filter(Boolean)
        .map(action => put(action)),
    );
  }
}

function* onRequestSaleyardPermissionData() {
  // Ready for use!
}

function* onRequestSystemData() {
  // ages
  // breeds
  // categories
  // grades
  // exemptions
  // labels
  // nomination terms
  // rounds
  // sexes
  // species
  yield put(requestSpeciesAttributes());
  // sale sub types
  yield put(SaleSubTypeAction.request());
  yield put(WeightRangesAction.request());
  yield put(DressingRangesAction.request());
  yield put(BillingTagsAction.request());
}

function* onRestoreSession() {
  // Request system data
  yield put({ type: REQUEST_SYSTEM_DATA });

  // We've refreshed so clear any app update status
  yield put(appUpdateComplete());
}

function* onSetActiveRoleSuccess(action) {
  const { hasRoleChanged } = action;
  // request role specific
  if (hasRoleChanged) {
    // If we know that the new role doesnt have access to the saleyard, boot them to one they do have access to.
    const { auth } = yield select();
    if (auth?.activeRole?.saleyards?.length > 0) {
      const currentSaleYardName = getSaleyardName();
      if (
        currentSaleYardName &&
        !auth.activeRole.saleyards
          .map(s => s.name)
          .includes(currentSaleYardName)
      ) {
        redirectToFirstSaleyardFromRole(auth);
      }
    }

    yield put({ type: REQUEST_USER_ROLE_DATA });
  }
}

function* onSetCurrentLivestockSaleSuccess({
  livestockSaleId,
  requestLivestockSaleData,
}) {
  if (livestockSaleId && requestLivestockSaleData) {
    yield put({ type: REQUEST_LIVESTOCK_SALE_DATA });
  }
}

function* heartBeatInterval() {
  clearHeartBeatInterval();

  const state = yield select();

  const refreshIntervalMs = getRefreshIntervalMs(state);
  const isHubConnected = getIsHubConnected(state);
  const firstTick = Date.now() + refreshIntervalMs;
  const channel = eventChannel(emit => {
    heartBeatTrigger(
      () => {
        getLivestockSaleId()
          ? emit(fetchLivestockSaleDataChanges(getApiSale(), getSaleyardName()))
          : emit(ServerHeartbeatAction.request());
      },
      () => {
        if (isHubConnected) {
          emit(pingFromApp());
        }
      },
      refreshIntervalMs,
      firstTick,
      Date.now(),
    );
    return () => {
      clearHeartBeatInterval();
    };
  });
  try {
    while (true) {
      const action = yield take(channel);
      yield put(action);
    }
  } finally {
    clearHeartBeatInterval();
  }
}

function* handleGetFailure(action) {
  const { isCatastrophic } = action;
  if (isCatastrophic) {
    // Failure to get this request means stuff pretty broken (eg a sale with no lots) so tell them
    const state = yield select();
    const dismissedUntil = getSetting(
      Settings.failedRequestModalDismissedUntil,
    )(state);
    if (!dismissedUntil || dismissedUntil < Date.now()) {
      yield put(setSetting(Settings.isFailedRequestsModalShowing, true));
    }
  }
}

export default function* systemSaga() {
  yield takeLatest(REQUEST_LIVESTOCK_SALE_DATA, onRequestSaleData);
  yield takeLatest(REQUEST_USER_ROLE_DATA, onRequestUserRoleData);
  yield takeLatest(REQUEST_SYSTEM_DATA, onRequestSystemData);
  yield takeEvery(
    DEPLOYMENT.FETCH_BULK.SUCCESS,
    onRequestDeploymentPermissionData,
  );
  yield takeEvery(SALEYARD.FETCH_BULK.SUCCESS, onRequestSaleyardPermissionData);

  yield takeEvery(API_RESPONSE.FETCH.SUCCESS, onApiResponseGetSuccess);
  yield takeEvery(API_RESPONSE.UPDATE.FAILURE, onApiResponseGetUpdateFailure);
  yield takeEvery(SIMULATE_REDUX_SAGA_ERROR, onSimulateReduxSagaError);

  yield takeLatest(RESTORE_SESSION, onRestoreSession);
  yield takeLatest(
    [
      SALE.FETCH_BULK.SUCCESS,
      SET_CURRENT_LIVESTOCKSALE.SUCCESS,
      REFRESH_INTERVAL.ACTION,
      HUB_CONNECTED,
      HUB_DISCONNECTED,
    ],
    heartBeatInterval,
  );

  yield takeLatest(
    SET_CURRENT_LIVESTOCKSALE.SUCCESS,
    onSetCurrentLivestockSaleSuccess,
  );
  yield takeLatest(SET_ACTIVE_ROLE.SUCCESS, onSetActiveRoleSuccess);
  yield takeLatest(SET_ACTIVE_ROLE.RESET, onSetActiveRoleSuccess);
  yield takeEvery(API_RESPONSE.FETCH.FAILURE, handleGetFailure);
}
