import React, { useMemo, useState } from "react";

import { useField } from "formik";
import { isEmpty, uniqBy } from "lodash";
import sortBy from "lodash/sortBy";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { addPropertyToBusiness, PropertyAction } from "actions";

import { Autocomplete } from "components/Form/FormikControls";

import { validatePIC } from "lib/PICValidator";
import { getDefaultPropertyId } from "lib/properties";

import {
  getBusinessById,
  getBuyerWayPropertiesByBusinessId,
  getCurrentSaleyardId,
  getProperties,
  getPropertiesByBusinessId,
} from "selectors";

import { useFieldHelpers, useUpdateFormWithOfflineLookup } from "hooks";

const getOptionValue = option => option?.id;

export const PICField = ({
  buyerWayName,
  name,
  onChangeExtra = undefined,
  label,
  businessId,
  suggestedValueFieldName,
  disabled,
  staticOptionLabel,
  addToBusiness = true,
  showAllProperties = false,
}) => {
  const { setValue, setError, setTouched } = useFieldHelpers(name);

  const business = useSelector(getBusinessById(businessId));

  const isDisabled = useMemo(() => {
    if (disabled === true) {
      // Explicitly disabled
      return true;
    } else {
      // Disable if we are adding to property in the context of a business, but have no business selected.
      return addToBusiness ? isEmpty(business) : false;
    }
  }, [addToBusiness, business, disabled]);

  const properties = useSelector(getProperties);
  const businessProperties = useSelector(getPropertiesByBusinessId(businessId));
  const businessBuyerWayProperties = useSelector(
    getBuyerWayPropertiesByBusinessId(businessId),
  );

  const saleyardId = useSelector(getCurrentSaleyardId);

  const dispatch = useDispatch();

  const combinedBusinessProperties = useMemo(
    () => [
      ...businessProperties,
      // Include buyer way properties. This may include Properties which are not
      // included in businessProperties (e.g. when a visible buyer way has a related property that is hidden)
      ...(businessBuyerWayProperties || []),
    ],
    [businessProperties, businessBuyerWayProperties],
  );

  const stateOptions = sortBy(
    uniqBy(
      showAllProperties ? properties : combinedBusinessProperties,
      "PIC".PIC,
    ),
  );

  useUpdateFormWithOfflineLookup(name);
  const [tempOptions, setTempOptions] = useState([]);

  const addNewProperty = PIC => {
    const uppercasePIC = PIC.toUpperCase();
    if (!validatePIC(uppercasePIC)) {
      const tempInvalidOption = { PIC: uppercasePIC, hide: true };
      // Add a temp options that is not visible in the list so we dont get warnings about the
      // selected option not existing from material ui.
      setTempOptions([...tempOptions, tempInvalidOption]);
      setTouched(true, false)
        .then(() => setValue(tempInvalidOption, false))
        .then(() => setError("Invalid PIC", false));
      return;
    }

    // For now just add PIC, but we could just as easily open another modal.
    const propertyId =
      Object.values(properties).find(p => p.PIC === uppercasePIC)?.id ||
      uuidv4();

    if (addToBusiness) {
      dispatch(
        addPropertyToBusiness(
          {
            id: propertyId,
            PIC: uppercasePIC,
            name: "",
          },
          business.id,
        ),
      );
    } else {
      dispatch(
        PropertyAction.create({
          id: propertyId,
          PIC: uppercasePIC,
          name: "",
        }),
      );
    }

    setValue(propertyId, true);
  };

  const options = useMemo(
    () => uniqBy([...stateOptions, ...tempOptions], "PIC"),
    [stateOptions, tempOptions],
  );

  const getOptionLabel = option =>
    staticOptionLabel ||
    `${
      getDefaultPropertyId(business, saleyardId, buyerWayName) === option.id
        ? "* "
        : ""
    }${[(option.PIC || "").toUpperCase(), option.name]
      .filter(Boolean)
      .join(" - ")}` ||
    "";

  return (
    <Autocomplete
      name={name}
      clearOnBlur
      selectOnFocus
      handleHomeEndKeys
      label={label}
      options={options}
      onChangeExtra={onChangeExtra}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      handleNew={addNewProperty}
      suggestedValueFieldName={suggestedValueFieldName}
      disabled={isDisabled}
    />
  );
};

export const BusinessPICField = ({
  businessField,
  buyerWayName,
  disabled = false,
  label,
  name,
  onChangeExtra = undefined,
  staticOptionLabel,
}) => {
  const [{ value: businessId }] = useField(businessField);
  return (
    <PICField
      buyerWayName={buyerWayName}
      name={name}
      onChangeExtra={onChangeExtra}
      label={label}
      businessId={businessId}
      key={businessId}
      disabled={disabled}
      staticOptionLabel={staticOptionLabel}
    />
  );
};
