import { GLCodeRenderer } from "lib/agGrid/renderers";
import { BusinessesRenderer } from "lib/agGrid/renderers/businessesRenderer";

export const SundryTemplateColId = {
  NAME: "a27011b9-fb71-4384-a555-5d5feb135cdf",
  GL_CODE: "5e9fb8c3-5d94-4bbe-976d-0300c200962f",
  GST_METHOD: "64cfeb72-11ec-4158-a6bd-8eee9421a887",
  NOTE: "8e0f5f7b-e283-4836-a233-25cbf57ea7d0",
  TAX_TYPE: "bcc2b56d-a9b1-401f-a2b5-132f8855a7c4",
  PAID_FROM_BUSINESS: "5fd206e5-e77f-4405-b295-e88c07f5b416",
  PAID_FROM_SEARCH_FILTER: "320a44a3-e6a0-4bf1-b11e-1ac6437fe55c",
  PAID_FROM_TYPE: "76326a91-77a0-4711-bf75-22c28d949e3e",
  PAID_TO_BUSINESS: "d04f08aa-c869-429c-b47d-93aa857c798f",
  PAID_TO_SEARCH_FILTER: "d3287b03-245d-4883-a7f6-a80fd1ba0993",
  PAID_TO_TYPE: "4c97449f-9441-43a4-8fa4-b0efa090a3a0",
};

export const SundryTemplateColumnDef = {
  [SundryTemplateColId.NAME]: {
    colId: SundryTemplateColId.NAME,
    field: "sundryTemplate.name",
    headerName: "Name",
  },
  [SundryTemplateColId.GL_CODE]: {
    colId: SundryTemplateColId.GL_CODE,
    field: "sundryTemplate.glCode",
    headerName: "Account",
    cellRenderer: GLCodeRenderer,
  },
  [SundryTemplateColId.GST_METHOD]: {
    colId: SundryTemplateColId.GST_METHOD,
    field: "sundryTemplate.gstMethod",
    headerName: "GST Method",
  },
  [SundryTemplateColId.NOTE]: {
    colId: SundryTemplateColId.NOTE,
    field: "sundryTemplate.note",
    headerName: "Note",
  },
  [SundryTemplateColId.TAX_TYPE]: {
    colId: SundryTemplateColId.TAX_TYPE,
    field: "sundryTemplate.taxType",
    headerName: "Tax Type",
  },
  [SundryTemplateColId.PAID_FROM_BUSINESS]: {
    colId: SundryTemplateColId.PAID_FROM_BUSINESS,
    field: "sundryTemplate.paidFromBusinessId",
    headerName: "Paid From Business",
    cellRenderer: BusinessesRenderer,
  },
  [SundryTemplateColId.PAID_FROM_SEARCH_FILTER]: {
    colId: SundryTemplateColId.PAID_FROM_SEARCH_FILTER,
    field: "sundryTemplate.paidFromSearchFilter",
    headerName: "Paid From Search Filter",
  },
  [SundryTemplateColId.PAID_FROM_TYPE]: {
    colId: SundryTemplateColId.PAID_FROM_TYPE,
    field: "sundryTemplate.paidFromType",
    headerName: "Paid From Type",
  },
  [SundryTemplateColId.PAID_TO_BUSINESS]: {
    colId: SundryTemplateColId.PAID_TO_BUSINESS,
    field: "sundryTemplate.paidToBusinessId",
    headerName: "Paid To Business",
    cellRenderer: BusinessesRenderer,
  },
  [SundryTemplateColId.PAID_TO_SEARCH_FILTER]: {
    colId: SundryTemplateColId.PAID_TO_SEARCH_FILTER,
    field: "sundryTemplate.paidToSearchFilter",
    headerName: "Paid To Search Filter",
  },
  [SundryTemplateColId.PAID_TO_TYPE]: {
    colId: SundryTemplateColId.PAID_TO_TYPE,
    field: "sundryTemplate.paidToType",
    headerName: "Paid To Type",
  },
};
