import React from "react";

import { useSelector } from "react-redux";

import AgGridContainer from "components/AgGrid/AgGridContainer";
import { Column, Row } from "components/Layout";
import { Paper } from "components/Paper";

import { AgGridTables } from "constants/aggrid";
import { SundryTemplateColumnDef } from "constants/columnDefinitions/sundryTemplates";

import { selectSundryTemplatesAggridData } from "selectors/aggrid/sundryTemplates";

export const columnDefs = Object.values(SundryTemplateColumnDef);

export const SundryTemplatesTable = () => {
  const rowData = useSelector(selectSundryTemplatesAggridData);
  return (
    <AgGridContainer
      rowData={rowData}
      columnDefs={columnDefs}
      tableName={AgGridTables.SUNDRY_TEMPLATES}
    />
  );
};

export const SundryTemplates = () => {
  return (
    <Row flexGrow full>
      <Column margin={2} gridGap={2} full>
        <Paper square>
          <SundryTemplatesTable />
        </Paper>
      </Column>
    </Row>
  );
};
